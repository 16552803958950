import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"

class KonfirmasiPembayaran extends React.Component{
    state={
        list_pembayaran:[],
        selected_pembayaran:"",
        filter:{
            page:1,
            per_page:10,
            count_page:0
        },
        reject_pembayaran:{
            is_open:false,
            id_user_pembayaran:"",
            keterangan:""
        }
    }

    componentDidMount=()=>{
        this.getListPembayaran()
    }
    getListPembayaran=()=>{
        API.post("/admin/user_pembayaran/list_pembayaran", JSON.stringify({
            token:access_token(),
            filter:this.state.filter
        }))
        .then(res=>{
            this.setState({
                list_pembayaran:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getPembayaranStatus=status=>{
        switch(status){
            case "confirm":
                return <span className="badge bg-warning text-dark">Menunggu Konfirmasi</span>
            break;
            case "applied":
                return <span className="badge bg-success">Terverifikasi</span>
            break;
            case "rejected":
                return <span className="badge bg-danger">Verifikasi Ditolak</span>
            break;
        }
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getListPembayaran()
        })
    }

    //expand/collapse pembayaran
    toggleListPembayaran=id=>{
        this.setState({
            selected_pembayaran:this.state.selected_pembayaran!==id?id:""
        })
    }
    applyPembayaran=id=>{
        API.post("/admin/user_pembayaran/apply_pembayaran", JSON.stringify({
            token:access_token(),
            id_user_pembayaran:id
        }))
        .then(res=>{
            this.getListPembayaran()
            this.toggleListPembayaran("")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    showRejectPembayaran=id=>{
        this.setState({
            reject_pembayaran:{
                is_open:true,
                id_user_pembayaran:id,
                keterangan:""
            }
        })
    }
    hideRejectPembayaran=()=>{
        this.setState({
            reject_pembayaran:{
                is_open:false,
                id_user_pembayaran:"",
                keterangan:""
            }
        })
    }
    typeRejectPembayaran=e=>{
        const target=e.target

        this.setState({
            reject_pembayaran:update(this.state.reject_pembayaran, {
                [target.name]:{$set:target.value}
            })
        })
    }
    rejectPembayaran=(e)=>{
        e.preventDefault()
        
        const {reject_pembayaran}=this.state
        API.post("/admin/user_pembayaran/reject_pembayaran", JSON.stringify({
            token:access_token(),
            id_user_pembayaran:reject_pembayaran.id_user_pembayaran,
            keterangan:reject_pembayaran.keterangan
        }))
        .then(res=>{
            this.getListPembayaran()
            this.toggleListPembayaran("")
            this.hideRejectPembayaran()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {list_pembayaran, selected_pembayaran, filter, reject_pembayaran}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">User/Pengguna</th>
                                                    <th class="border-0">Paket Pilihan</th>
                                                    <th class="border-0">Harga</th>
                                                    <th class="border-0">Status</th>
                                                    <th class="border-0">Created At</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list_pembayaran.map((list, idx)=>(
                                                    <>
                                                        <tr>
                                                            <td className="align-middle">{(idx+1)*filter.page}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="avatar">
                                                                        <Avatar 
                                                                            data={{
                                                                                avatar_url:list.avatar_url,
                                                                                nama_2:akronim(list.nama_lengkap)
                                                                            }}
                                                                            circle
                                                                        />
                                                                    </span>
                                                                    <span className="fw-semibold text-capitalize ms-2">{list.nama_lengkap}</span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle">
                                                                <span className="fw-bold text-success text-capitalize">{list.paket_selected}</span> / {list.paket_durasi} bulan
                                                            </td>
                                                            <td className="align-middle">
                                                                <NumberFormat 
                                                                    value={list.paket_harga} 
                                                                    displayType={'text'} 
                                                                    thousandSeparator={true}
                                                                />
                                                            </td>
                                                            <td className="align-middle">{this.getPembayaranStatus(list.status)}</td>
                                                            <td className="align-middle">{moment(list.created_at, "YYYY-MM-DD HH:mm:ss").format("D MMMM, YYYY")}</td>
                                                            <td className="align-middle pe-0">
                                                                <button className="btn btn-secondary btn-sm ms-auto" onClick={(e)=>this.toggleListPembayaran(list.id_user_pembayaran)}>
                                                                    <svg class={classNames("icon icon-sm arrow", {"rotate-90":selected_pembayaran===list.id_user_pembayaran})} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                                    Detail
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        {selected_pembayaran===list.id_user_pembayaran&&
                                                            <tr>
                                                                <td colSpan="7">
                                                                    <div className="row flex-column">
                                                                        <div className="col-md-6">
                                                                            <div className="row">
                                                                                <div className="col-3 fw-semibold">Kadaluarsa Pada</div>
                                                                                <div className="col d-flex align-items-start">
                                                                                    <span className="me-2">: </span>
                                                                                    <p className="mb-0">{moment(list.paket_expired, "YYYY-MM-DD").format("D MMMM, YYYY")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 mt-1">
                                                                            <div className="row">
                                                                                <div className="col-3 fw-semibold">Keterangan</div>
                                                                                <div className="col d-flex align-items-start">
                                                                                    <span className="me-2">: </span>
                                                                                    <p className="mb-0">{list.keterangan}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        {list.bukti_pembayaran.map(d=>(
                                                                            <a href={BASE_URL+"/"+d.path} target="_blank"
                                                                                className="btn btn-image d-flex align-items-center justify-content-center me-2"
                                                                            >
                                                                                <img src={BASE_URL+"/"+d.path}/>
                                                                            </a>
                                                                        ))}
                                                                    </div>
                                                                    <div className="mt-4 mb-2">
                                                                        <button 
                                                                            className="btn btn-success" 
                                                                            onClick={()=>this.applyPembayaran(list.id_user_pembayaran)}
                                                                        >
                                                                            Terima Konfirmasi
                                                                        </button>
                                                                        <button 
                                                                            className="btn btn-danger ms-2" 
                                                                            onClick={()=>this.showRejectPembayaran(list.id_user_pembayaran)}
                                                                        >
                                                                            Tolak Konfirmasi
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-700" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-700 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL REJECT PEMBAYARAN */}
                <Modal show={reject_pembayaran.is_open} onHide={this.hideRejectPembayaran}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tolak Konfirmasi</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.rejectPembayaran}>
                    <Modal.Body>
                        <div class="p-2">
                            <textarea 
                                className="form-control" 
                                placeholder="Isikan Keterangan ..." 
                                rows="3"
                                value={reject_pembayaran.keterangan}
                                name="keterangan"
                                onChange={this.typeRejectPembayaran}
                            >

                            </textarea>
                            <span className="form-text">Keterangan digunakan untuk memberitahu pengguna!</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto">Batal</button>
                        <button type="submit" class="btn btn-danger">Tolak Konfirmasi</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}

export default navAuth(KonfirmasiPembayaran, {
    title:"Konfirmasi Pembayaran"
})
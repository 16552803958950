import React, { useState } from "react"
import classNames from "classnames"
import { Accordion, Dropdown } from "react-bootstrap"
import { Link, Switch, Route } from "react-router-dom"
import Avatar from "../../component/ui/avatar"
import { akronim, login_data } from "../../config/config"
import {ImUsers} from "react-icons/im"

//pages
import DashboardMenu from "./dashboard"
import KonfirmasiPembayaran from "./konfirmasi_pembayaran"
import Users from "./users"

class Dashboard extends React.Component{
    state={
        active_accordion:"",
        mobile_menu_show:false
    }

    //menu
    toggleAccordion=menu=>{
        let accordion
        if(menu===this.state.active_accordion) accordion=""
        else accordion=menu

        this.setState({
            active_accordion:accordion
        })
    }
    toggleMobileMenu=()=>{
        this.setState({
            mobile_menu_show:!this.state.mobile_menu_show
        })
    }

    //action
    logout=()=>{
        localStorage.removeItem("user_data")
        window.location.href="/?status=logout"
    }

    render(){
        const {active_accordion, mobile_menu_show}=this.state

        return (
            <>
                <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
                    <a class="navbar-brand me-lg-5" href="">
                        <img class="navbar-brand-dark" src="/img/logo/light.svg" alt="Logo"/>
                        <img class="navbar-brand-light" src="/img/logo/dark.svg" alt="logo" />
                    </a>
                    <div class="d-flex align-items-center">
                        <button class="navbar-toggler d-lg-none collapsed" type="button" onClick={this.toggleMobileMenu}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
    
                <nav id="sidebarMenu" class={classNames("sidebar d-lg-block bg-gray-800 text-white collapse", {"show":mobile_menu_show})}>
                    <div class="sidebar-inner px-4 pt-3">
                        <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div class="d-flex align-items-center">
                                <div class="avatar avatar-lg bg-white rounded-circle text-dark me-4">
                                    <Avatar
                                        data={{
                                            avatar_url:login_data().avatar_url,
                                            nama_2:akronim(login_data().nama_lengkap)
                                        }}
                                        circle
                                    />
                                </div>
                                <div class="d-block">
                                    <h2 class="h5 mb-3 text-capitalize">Hi, {login_data().nama_lengkap}</h2>
                                    <button type="button" className="btn btn-secondary btn-sm d-inline-flex align-items-center" onClick={this.logout}>
                                        <svg class="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>            
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                            <div class="collapse-close d-md-none cursor-pointer">
                                <span onClick={this.toggleMobileMenu}>
                                    <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </span>
                            </div>
                        </div>
                        <Accordion as="ul" className="nav flex-column pt-3 pt-md-0" activeKey={active_accordion}>
                            <li>
                                <a href="#" className="nav-link d-flex align-items-center">
                                    <span class="sidebar-icon">
                                        <img src="/img/logo/light.svg" height="20" width="20" alt="Logo"/>
                                    </span>
                                    <span class="mt-1 ms-1 sidebar-text">Garapan Admin</span>
                                </a>
                            </li>
                            <li class="nav-item mt-3">
                                <Link to="/" className="nav-link">
                                    <span class="sidebar-icon">
                                        <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                                    </span> 
                                    <span class="sidebar-text">Dashboard</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/konfirmasi_pembayaran" className="nav-link">
                                    <span class="sidebar-icon">
                                        <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path><path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    </span>
                                    <span class="sidebar-text">Pembayaran</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/users" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImUsers className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Users/Pengguna</span>
                                </Link>
                            </li>
                            {/* <Accordion.Item as="li" eventKey="component" className={classNames("nav-item", {"active":active_accordion==="component"})}>
                                <span
                                    class="nav-link collapsed d-flex justify-content-between align-items-center"
                                    onClick={()=>this.toggleAccordion("component")}
                                >
                                    <span>
                                        <span class="sidebar-icon">
                                            <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path><path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                        </span> 
                                        <span class="sidebar-text">Konfirmasi</span>
                                    </span>
                                    <span class={classNames("link-arrow", {"rotate-90":active_accordion==="component"})}>
                                        <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    </span>
                                </span>
                                <Accordion.Collapse
                                    as="div"
                                    eventKey="component"
                                    className={classNames("multi-level")}
                                >
                                    <ul class="flex-column nav">
                                        <li class="nav-item">
                                            <Link to="/konfirmasi_pembayaran"
                                            <a class="nav-link" target="_blank"
                                                href="https://themesberg.com/docs/volt-bootstrap-5-dashboard/components/accordions/">
                                                <span class="sidebar-text">Pembayaran</span>
                                            </a>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item> */}
                        </Accordion>
                    </div>
                </nav>
                
                <main class="content">
    
                    <Switch>
                        <Route exact path="/" component={DashboardMenu}/>
                        <Route exact path="/konfirmasi_pembayaran" component={KonfirmasiPembayaran}/>
                        <Route exact path="/users" component={Users}/>
                    </Switch>

                    {/* FOOTER */}
                    <footer class="bg-white rounded shadow p-5 mb-4 mt-4">
                        <div class="row">
                            <div class="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
                                <p class="mb-0 text-center text-lg-start">Copyright © <span class="current-year">2021</span> <a class="text-primary fw-normal text-decoration-underline" href="https://themesberg.com" target="_blank">Garapan.id</a></p>
                            </div>
                        </div>
                    </footer>
                </main>
            </>
        )
    }
}

export default Dashboard
import React from "react"
import NumberFormat from "react-number-format"
import { API } from "../../../config/api"
import { access_token, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"

class DashboardMenu extends React.Component{
    state={
        info:{
            instansi:0,
            users:0
        }
    }

    componentDidMount=()=>{
        this.getDashboardInfo()
    }
    getDashboardInfo=()=>{
        API.post("/admin/dashboard/dashboard_info", JSON.stringify({
            token:access_token()
        }))
        .then(res=>{
            this.setState({
                info:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {info}=this.state

        return (
            <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl-4 mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body">
                                <div class="row d-block d-xl-flex align-items-center">
                                    <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div class="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path></svg>
                                        </div>
                                        <div class="d-sm-none">
                                            <h2 class="h5">Users/Pengguna</h2>
                                            <h3 class="fw-extrabold mb-1">
                                                <NumberFormat
                                                    displayType="text"
                                                    value={info.users}
                                                    thousandSeparator
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-7 px-xl-0">
                                        <div class="d-none d-sm-block">
                                            <h2 class="h6 text-gray-400 mb-0">Users/Pengguna</h2>
                                            <h3 class="fw-extrabold mb-2">
                                                <NumberFormat
                                                    displayType="text"
                                                    value={info.users}
                                                    thousandSeparator
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-4 mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body">
                                <div class="row d-block d-xl-flex align-items-center">
                                    <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div class="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                                            <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div class="d-sm-none">
                                            <h2 class="fw-extrabold h5">Total Instansi</h2>
                                            <h3 class="mb-1">
                                                <NumberFormat
                                                    displayType="text"
                                                    value={info.instansi}
                                                    thousandSeparator
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-7 px-xl-0">
                                        <div class="d-none d-sm-block">
                                            <h2 class="h6 text-gray-400 mb-0">Total Instansi</h2>
                                            <h3 class="fw-extrabold mb-2">
                                                <NumberFormat
                                                    displayType="text"
                                                    value={info.instansi}
                                                    thousandSeparator
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-4 mb-4">
                        <div class="card border-0 shadow">
                            <div class="card-body">
                                <div class="row d-block d-xl-flex align-items-center">
                                    <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div class="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                            <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <div class="d-sm-none">
                                            <h2 class="fw-extrabold h5">Total Earning</h2>
                                            <h3 class="mb-1">50.88%</h3>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xl-7 px-xl-0">
                                        <div class="d-none d-sm-block">
                                            <h2 class="h6 text-gray-400 mb-0">Total Earning</h2>
                                            <h3 class="fw-extrabold mb-2">50.88%</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default navAuth(DashboardMenu, {
    title:"Dashboard"
})